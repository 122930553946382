<template>
  <v-container id="open-item" v-if="category">
    <v-row justify="space-between">
      <v-col
        v-for="item in itemsWithCategory(category.uuid)"
        :key="item.uuid.toString()"
        cols="6"
        md="4"
      >
        <OpenItemCircleMini
          class="rounded m-auto"
          @click="goToItem(item)"
          :item="item"
          :selectedLocale="language"
        ></OpenItemCircleMini>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OpenItemCircleMini from "@/components/openItem/OpenItemCircleMini";

export default {
  components: { OpenItemCircleMini },
  mounted() {
    this.$store.commit("system/setBreadcrumbs", this.breadcrumbs);
  },
  watch: {
    "$route.params.category"() {
      this.$store.commit("system/setBreadcrumbs", this.breadcrumbs);
    },
    language() {
      this.$store.commit("system/setBreadcrumbs", this.breadcrumbs);
    }
  },
  computed: {
    ...mapGetters("business/", ["infos", "items", "categories"]),
    ...mapGetters("system/", ["language"]),
    ...mapGetters("loader/", ["loading"]),
    category() {
      return this.categories.find(c => {
        return (
          this.objectContainValue(this.$route.params.category, c.name) ||
          c.uuid === this.$route.params.category
        );
      });
    },
    breadcrumbs() {
      return [
        {
          icon: "fas fa-home",
          to: "/"
        },
        {
          text: this.$t("application.pages.menu.title"),
          to: "/menu",
          exact: true,
          disabled: false
        },
        {
          text: this.category.name[this.language.lcid],
          disabled: true
        }
      ];
    },
    computedItems() {
      let items = this.items;
      function compare(a, b) {
        const bandA = a.position;
        const bandB = b.position;

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      }
      return items.sort(compare);
    },
    categoriesNamed() {
      return this.categories.map(f => f.name[this.business.language.lcid]);
    }
  },
  data() {
    return {
      searchText: "",
      showMenuDialog: false
    };
  },
  methods: {
    itemsWithCategory(uuid) {
      return this.computedItems
        .filter(m => m.category === uuid)
        .filter(m => m.available);
    },
    goToItem(item) {
      this.$router.push(
        `/menu/${this.category.name[this.language.lcid]}/${
          item.title[this.language.lcid]
        }`
      );
    },
    objectContainValue(value, object) {
      let found = false;
      for (let prop in object) {
        if (object[prop].trim() === value.trim()) {
          found = true;
        }
      }
      return found;
    }
  }
};
</script>
