<template>
  <div>
    <v-row align="center" justify="center" style="margin: auto">
      <v-col cols="auto" class="pa-0">
        <v-img
          :src="getImageWithSize"
          eager
          class="clickable circle-radius image-contain"
          @click="$emit('click')">
          <template v-slot:placeholder>
            <v-skeleton-loader class="rounded" type="image"></v-skeleton-loader>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto" class="py-0 px-6">
        <div v-if="item.title[selectedLocale.lcid]" class="span-div">
          {{ item.title[selectedLocale.lcid] }}
        </div>
        <div v-else class="span-div">
          {{ $t("application.pages.menu.notavailable") }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="business.showPrices">
      <v-col class="pa-1 caption text-center">
        <h2>{{ $n(item.price, "currency") }}</h2></v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: Object,
    photoSize: {
      default: 150,
      type: Number,
    },
    selectedLocale: Object,
    showMore: Boolean,
    isEditor: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      animate: false,
    };
  },
  computed: {
    ...mapGetters("business/", ["infos"]),
    business() {
      return this.infos;
    },
    getImageWithSize() {
      if (this.item.imageUrl) {
        // return this.item.imageUrl + "&width=" + 500 + "&height=" + 500;
        return this.item.imageUrlSmall;
      } else {
        return "/add.jpg";
      }
    },
  },
  methods: {
    addToItems(uuid) {
      if (!this.isEditor) {
        this.animate = !this.animate;
        this.$emit("addToItemsToogle", uuid);
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(1px);
}

.circle-radius {
  position: relative;
  border-radius: 10%;
  padding: 0.2rem;
  height: 15rem;
  width: 15rem;
  box-shadow: -1px -1px 2px hsla(0, 0%, 100%, 0.3),
    1px 1px 2px rgba(0, 0, 0, 0.178), inset -1px -1px 2px rgba(0, 0, 0, 0.178),
    inset 1px 1px 2px hsla(0, 0%, 100%, 0.3);
  transition: 1s ease;
}

.image-contain {
  object-fit: cover;
}
.span-div {
  font-family: "Advent Pro", sans-serif;
  font-size: 1.4rem;
  color: #064549;
  text-shadow: -0.5px 0.5px 0px #02464963;
  text-transform: capitalize !important;
  letter-spacing: 1px;
  text-align: center;
}
h2 {
  font-family: "Helvetica";
  color: #064549;
  font-size: 1.5rem;
  font-weight: bold;
}
.clickable:hover {
  cursor: pointer;
}
</style>
